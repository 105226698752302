import { keyframes, css } from 'styled-components'

const fadeInKeyFrames = keyframes`
  0% {
    filter: blur(5px);
    opacity: 0;
  }

  100% {
    filter: blur(0);
    opacity: 1;
  }
`

const moveInBottomKeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  80% {
   transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const moveInLeftKeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`
const moveInTopKeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const pulsateKeyFrames = keyframes`
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
`;


export const fadeIn = ({ time = '1s', type = 'ease' } = {}) => (
  css`animation: ${time} ${fadeInKeyFrames} ${type};`
)

export const moveInBottom = ({ time = '1s', type = 'ease' } = {}) => (
  css`animation: ${time} ${moveInBottomKeyFrames} ${type};`
)

export const moveInTop = ({ time = '1s', type = 'ease' } = {}) => (
  css`animation: ${time} ${moveInTopKeyFrames} ${type};`
)

export const moveInLeft = ({ time = '1s', type = 'ease' } = {}) => (
  css`animation: ${time} ${moveInLeftKeyFrames} ${type};`
)

export const pulsate = ({time = '1s', type = 'ease-out'} = {}) => (
  css`animation: ${time} ${pulsateKeyFrames} ${type} !important;
      animation-iteration-count: infinite !important;
      animation-delay: 0.5s !important;
      `
)

